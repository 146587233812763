import { Link } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const Privacy = () => {
  return (
    <Layout>
      <Seo />
      <section className="pt-60px px-30px mt-70px">
        <div className="max-w-700px mx-auto">
          <h1 className="text-3xl text-primary leading-1.2 mb-10px">
            Privacystatement
          </h1>
          <p className="text-lg text-primary leading-1.625 mb-30px">
            In dit privacystatement laat Eerstmediation.nl zien hoe zij omgaat
            met de persoonsgegevens en privacy van haar cliënten. Deze
            transparantie is een van de eisen van de &nbsp;
            <Link
              to="https://autoriteitpersoonsgegevens.nl/nl/onderwerpen/avg-europese-privacywetgeving/algemene-informatie-avg"
              target="_blank"
              className="text-link cursor-pointer underline"
            >
              Algemene Verordening Gegevensbescherming
            </Link>
            . Welke persoonsgegevens worden verzameld en waarvoor?
          </p>

          <ul className="list-disc mb-30px pl-10">
            <li className="text-lg text-primary leading-1.625">
              Naam, adres, geboortedatum -> voor de vaststelling van de
              identiteit van de cliënten en het voeren van procedures;
            </li>
            <li className="text-lg text-primary leading-1.625">
              Email en telefoonnummer -> om contact te kunnen onderhouden met de
              cliënten;
            </li>
            <li className="text-lg text-primary leading-1.625">
              BSN -> in geval een procedure bij de rechtbank of voor het
              aanvragen van een toevoeging;
            </li>
            <li className="text-lg text-primary leading-1.625">
              Burgerlijke staat -> voor de vaststelling van de juridische
              positie van cliënten;
            </li>
            <li className="text-lg text-primary leading-1.625">
              Nationaliteit -> voor de vaststelling van het toepasselijke recht.
            </li>
          </ul>
          <p className="text-lg text-primary leading-1.625 mb-30px">
            Eerstmediation.nl maakt geen kopie van paspoorten. Wel wordt inzage
            in paspoort, ID of rijbewijs gevraagd ter identificatie van
            cliënten.
          </p>

          <h2 className="text-primary text-27px mb-30px leading-1.2">
            Is het verwerken van deze persoonsgegevens rechtmatig?
          </h2>
          <p className="text-primary text-lg leading-1.625 mb-30px">
            Het verwerken van bovengenoemde persoonsgegevens vindt plaats omdat
            het noodzakelijk is voor de uitvoering van de opdracht. Vaak ligt er
            een wettelijke plicht aan ten grondslag. Bovendien geven cliënten
            door het verstrekken van de opdracht toestemming voor het verwerken
            van de persoonsgegevens. Dit is vastgelegd in de &nbsp;
            <Link
              to="/algemene-voorwaarden"
              className="cursor-pointer text-link underline"
            >
              algemene voorwaarden
            </Link>
            &nbsp; van Eerstmediation.nl welke van toepassing zijn verklaard op
            elke opdracht.
          </p>

          <h2 className="text-primary text-27px mb-30px leading-1.2">
            Hoe en hoe lang worden deze gegevens bewaard?
          </h2>
          <p className="text-primary text-lg leading-1.625 mb-30px">
            De persoonsgegevens worden bewaard in een fysiek en een digitaal
            dossier gedurende tenminste 7 jaar en maximaal 10 jaar.
            Eerstmediation.nl voldoet hiermee aan een wettelijke plicht. De
            fysieke dossiers bevinden zich in een afgesloten ruimte. Voor de
            digitale dossiers maakt Eerstmediation.nl gebruik van cloudservices
            van een gerenomeerd bedrijf met een hoog beveiligingsniveau.
          </p>

          <h2 className="text-primary text-27px mb-30px leading-1.2">
            Wat zijn de rechten van de degene wiens gegeven worden verwerkt?
          </h2>
          <p className="text-primary text-lg leading-1.625 mb-30px">
            Cliënten hebben recht van inzage, rectificatie en wissing van de
            verwerkte persoonsgegevens. Een verzoek hiertoe kan te allen tijde
            worden gedaan. Hieraan zal door Eerstmediation.nl binnen 4 weken
            kosteloos aan worden voldaan, tenzij wettelijke bepalingen van
            bewaarplicht dit verhinderen. Wanneer cliënten het idee hebben dat
            er op onjuiste wijze wordt omgegaan met hun persoonsgegevens kunnen
            zij hierover een klacht indienen bij Eerstmediation.nl. Zij kunnen
            ook een klacht indienen bij de toezichthoudende instantie
            (Autoriteit Persoonsgegevens) of een gerechtelijke procedure starten
            bij de rechtbank.
          </p>

          <h2 className="text-primary text-27px mb-30px leading-1.2">
            Wat gebeurt erbij een data-lek?
          </h2>
          <p className="text-primary text-lg leading-1.625 mb-30px">
            Er is sprake van een data-lek als persoonsgegevens onbedoeld
            verloren zijn gegaan, zijn gewijzigd of toegankelijk zijn gemaakt
            voor derden. Een voorbeeld hiervan is als een computersysteem
            gehackt is. Eerstmediation.nl is verplicht eventuele data-lekken te
            documenteren en hiervan binnen 72 uur melding te doen aan de
            Autoriteit Persoonsgegevens.
          </p>

          <p className="text-primary text-lg leading-1.625 mb-30px">
            Voor vragen over de verwerking van persoonsgegevens kunt u{" "}
            <Link to="/contact" className="cursor-pointer text-link underline">
              contact
            </Link>{" "}
            opnemen met Rianne Hillenaar-Cöp van Eerstmediation.nl
          </p>

          <p className="text-primary text-lg leading-1.625 mb-30px">
            Cuijk, laatstelijk gewijzigd op 15 april 2018
          </p>
        </div>
      </section>
    </Layout>
  )
}

export default Privacy
